import { useTranslator } from "@sunrise/translator";
import { Button, Dialog } from "@sunrise/yallo-web-components";

import styles from "./search-dialog.module.css";
import { SearchInput } from "./search-input";

export const SearchDialog = ({
  onClose,
  open,
}: {
  onClose: (() => void) | undefined;
  open: boolean;
}) => {
  const t = useTranslator();

  return (
    <Dialog
      className={styles.searchDialog}
      open={open}
      closable
      onClose={onClose}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Button icon="arrowLeft" variant="text" hideLabel onClick={onClose}>
            {t("button_go_back")}
          </Button>
          <SearchInput />
        </div>
        <div className={styles.searchFilters}></div>
        <div className={styles.searchResults}></div>
      </div>
    </Dialog>
  );
};
