import { useEffect, useState } from "react";

import { useTranslator } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

import { SearchDialog } from "@/components/dialogs/search-dialog";

export function SearchButton() {
  const t = useTranslator();
  const [searchOpened, setSearchOpened] = useState(false);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.altKey || event.metaKey) {
        return;
      }

      switch (event.key) {
        case "/": {
          setSearchOpened(true);
          break;
        }
      }
    };

    document.addEventListener("keydown", handler);

    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, []);

  return (
    <>
      <Button
        icon="search"
        variant="text"
        hideLabel
        onClick={() => setSearchOpened(true)}
      >
        {t("menu_search")}
      </Button>
      <SearchDialog
        open={searchOpened}
        onClose={() => setSearchOpened(false)}
      />
    </>
  );
}
